import React from 'react';
import PropTypes from 'prop-types';

import { Link } from 'components/elements/Links/Link';
import { Layout } from 'components/layouts/Layout';

const PageNotFound = ({ location }) => (
  <Layout location={location}>
    <div className="section-wide">
      <div style={{ padding: '64px 0', textAlign: 'center' }}>
        <h1 style={{ marginBottom: '32px' }}>Page not found.</h1>
        <p style={{ marginBottom: '32px' }}>
          {location.pathname} page does not exist.
        </p>
        <Link href="/" variant="lg">
          Back to the home page
        </Link>
      </div>
    </div>
  </Layout>
);

PageNotFound.propTypes = {
  location: PropTypes.shape(),
};

export default PageNotFound;
